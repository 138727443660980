(function ($) {
  Drupal.behaviors.productBreadcrumbV1 = {
    attach: function (context) {
      var $breadCrumbs = $('.js-breadcrumbs-v1', context);
      var $levelCount = $('.js-breadcrumb__level--count', $breadCrumbs);

      if ($breadCrumbs.parents().hasClass('field-elc-mpp-content')) {
        $levelCount.removeClass('hidden');
      }
    }
  };
})(jQuery);
